$color_blue_500: #17466f;

body {
  background-color: $color_blue_500;
}

// Fondo Login, Registro, ForgotPassword
.dafi-fondo-auth {
  background-image: url("../img/fondo_auth.jpg");
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.buscador {
  position: relative;
  margin-right: 5px;
}
.buscador i {
  top: 8px;
  right: 5px;
  position: absolute;
}
.buscador input::placeholder {
  color: #000;
  opacity: 1; /* Firefox */
}
.buscador input {
  font-size: 1rem;
  padding-right: 1.7rem;
  outline: unset;
}
.buscador {
  input {
    &:focus {
      &:before {
        color: $color_blue_500 !important;
      }
      + i {
        color: #236da9;
      }
    }
  }
}

.opacity-img {
  img {
    opacity: 1;
  }
}

.active-change-inicio {
  &:before {
    display: block;
    width: 100%;
    height: 84px;
    content: "";
    background-image: url("../img/iconos/desktop/bt_inicio_hvr.png");
  }
  img {
    display: none !important;
  }
}

.active-change-cursos {
  &:before {
    display: block;
    width: 100%;
    height: 84px;
    content: "";
    background-image: url("../img/iconos/desktop/bt_miscursos_hvr.png");
  }
  img {
    display: none !important;
  }
}

.active-change-reconocimientos {
  &:before {
    display: block;
    width: 100%;
    height: 84px;
    content: "";
    background-image: url("../img/iconos/desktop/bt_reconocimientos_hvr.png");
  }
  img {
    display: none !important;
  }
}

.active-change-eventos {
  &:after {
    display: block;
    width: 100%;
    height: 84px;
    content: "";
    background-image: url("../img/iconos/desktop/bt_eventos_hvr.png");
  }
  img {
    display: none !important;
  }
}

.active-change-calificaciones {
  &:before {
    display: block;
    width: 100%;
    height: 84px;
    content: "";
    background-image: url("../img/iconos/desktop/bt_calificacion_hvr.png");
  }
  img {
    display: none !important;
  }
}
.active-change-certificados {
  &:before {
    display: block;
    width: 100%;
    height: 84px;
    content: "";
    background-image: url("../img/iconos/desktop/bt_certificados_hvr.png");
  }
  img {
    display: none !important;
  }
}

.content_menu_hamburguesa {
  // position: relative;

  .menu_hamburguesa_items {
    cursor: pointer;
  }

  .menu_hamburguesa_items {
    position: absolute;
    top: 85px;
    right: 0px;
    z-index: 1000;
  }
}

.content_search {
  position: relative;

  .search {
    z-index: 1000;
    position: absolute;
    width: 100vw;
    top: 52px;
    right: -112px;
    transform: translate(-0px, -0px);
    padding: 2rem;

    input {
      color: #222;
      width: 250px;
    }

    input::placeholder {
      color: #222;
    }
  }
}

.menu-opciones-perfil {
  position: relative;

  .menu-opciones-perfil-items {
    position: absolute;
    top: 55px;
    z-index: 1000;
    left: -20px;

    ul li {
      margin: 0 auto;
    }
  }
}

.hm-shadow {
  box-shadow: 1px 1px 10px #666;
}

.scroll-custome::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

.profile {
  border: 2px solid white;
  border-radius: 50%;
  padding: 4px;
}

.line-profile {
  width: 112px;
  height: 7px;
  border-bottom: 3px solid white;
}

ul.react-multi-carousel-track {
  margin-top: 1.5rem;
}

.react-multiple-carousel__arrow {
  background: #84b0c9;
  min-width: 29px;
  min-height: 23px;
  &:hover {
    background: #75a7c494;
  }
}

.react-multiple-carousel__arrow--right {
  right: 0;
}

.react-multiple-carousel__arrow--left {
  left: 0;
}

.customContainer {
  width: 70%;
}

.customContainerUrl {
  width: 100%;
  height: 50vh;
}

@media only screen and (max-width: 540px) {
  .customContainer {
    width: 100%;
  }
}

.DayPicker-Month {
  width: 75% !important;
}
.DayPicker-Footer {
  padding: 0 !important;
}
.DayPicker-Footer, button {
  text-align: center;
}

.formularios {
  padding: 1rem;
  .campo {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    input,
    select,
    .react-dropdown-select,
    textarea {
      padding: 0.5rem 1rem;
      /*             background-color: rgba($color: #fff, $alpha: 0.4); */
      border: 1px solid #797974;
      /* color: #000000; */
      margin-bottom: 8px;
      resize: none;
      &::placeholder {
        color: #fff;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #fff;
      }
      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #fff;
      }
    }
    input[type="checkbox"] {
      padding: 0.5rem 1rem;
      /*             background-color: rgba($color: #fff, $alpha: 0.4); */
      border: 1px solid #797974;
      /* color: #000000; */
      margin-bottom: 8px;
      resize: none;
      margin-right: 0.3em;
      &::placeholder {
        color: #fff;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #fff;
      }
      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #fff;
      }
    }
    label {
      color: #000;
    }
    .react-dropdown-select {
      color: #000;
      background-color: #fff;
      border-radius: 0.375rem;
    }
    .react-dropdown-select-input {
      padding: 0 !important;
      opacity: 0;
    }
  }
}


/* Quiz */

.answersBoxes {
}

.answersBoxes .answerInfo {
  background-color: #d3e3ef;
  border-radius: 14px;
  //height: 5rem;
}
.answersBoxes .answerInfo .numQuestion {
  background-color: #236da9;
  border-radius: 14px 14px 0 0;
  padding: 0.5rem;
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  color: #fff;
  letter-spacing: -0.25px;
}

.answersBoxes .answerForm {
  color: #001a1e;
  background-color: #fff;
  border-radius: 0px 0px 14px 14px;
  padding: 1.5rem 2.5rem;
  height: 12.65rem;
}
.boxShadow {
  box-shadow: 0px 0px 10px 2px #236da936;
  border-radius: 14px;
}
.answersBoxes .answerForm p.tittleQuestion {
  color: #236da9;
  font-size: 18px;
  font-weight: 900;
}
.answersBoxes .answerForm p.selectAnswer {
  font-size: 16px;
  font-weight: 300;
}

.answersBoxes .answerForm label,
.answersBoxes .answerForm span,
.answersBoxes .answerForm input {
  font-size: 16px;
  font-weight: 300;
  margin-right: 0.5rem;
}

.btnFinishExam {
  width: max-content;
  background-color: #236da9;
  border-radius: 12px;
  padding: 0.55rem 1rem;
  color: #fff;
  font-size: 16px;
  font-weight: 800;
}

/*  */
.addNewCard {
  display: flex;
  align-items: center;
}
.btnAddNewTopic {
  width: max-content;
  background: #ffffff;
  color: #236da9;
  padding: 0.15rem 0.75rem;
  font-size: 16px;
  font-weight: 800;
}

.btnForumSuccess {
  margin: 0.3125em;
  padding: 0.625em 1.1em;
  transition: box-shadow 0.1s;
  box-shadow: 0 0 0 3px transparent;
  font-weight: 500;
  color: #fff;
  font-size: 1em;
  border-radius: 0.25em;
  background: #236da9;
}
.btnForumSuccess:focus {
  box-shadow: 0 0 0 3px #236da985;
}

.btnForumCancel {
  margin: 0.3125em;
  padding: 0.625em 1.1em;
  transition: box-shadow 0.1s;
  box-shadow: 0 0 0 3px transparent;
  font-weight: 500;
  color: #fff;
  font-size: 1em;
  border-radius: 0.25em;
  background: #6e7881;
}
.btnForumCancel:focus {
  box-shadow: 0 0 0 3px #236da985;
}
.cardDiscussion {
  box-shadow: 0px 0px 10px 2px #00000059;
  border-radius: 14px;
  padding: 1rem;
}

.autorCard {
  display: flex;
  align-items: center;
}
.autorCard .autorContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.verticalLine {
  border-left: 1px solid #858585;
  height: 27.5px;
}


.resultSearchBar {
  z-index: 9;
  position: absolute;
  width: 100vw;
  top: 6.75rem;
  right: -5.35rem;
  transform: translate(0);
  padding: 1rem;
  text-align: center;
  background-color: #fff;
}

.DayPicker-Day {
  font-size: 1.35rem;
}

.swal2-styled.swal2-confirm {
  background-color: #2778c4 !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px #2778c480 !important;
}

@media only screen and (max-width: 1024px) {
  .cardSlider {
    width: 25vmax;
  }
  .answersBoxes .answerForm {
    margin-left: 0;
  }
}

@media only screen and (max-width: 540px) {
  .customContainer {
    width: 100%;
  }
}

@media only screen and (max-width: 420px) {
  .cardSlider {
    width: 30vmax;
  }
  .toRigth-hm {
    animation-name: unset;
    animation-duration: unset;
    animation-fill-mode: unset;
  }
  .answersBoxes .answerForm {
    padding: 1.5rem;
  }
}

@media only screen and (max-width: 360px) {
  .cardSlider {
    width: 30vmax;
  }
  .toRigth-hm {
    animation-name: unset;
    animation-duration: unset;
    animation-fill-mode: unset;
  }
}

