.containerScore {
  right: 0;
  .flex {
    .part-content-card {
      border-right: 1px solid gray;
      padding-right: 5px;
    }
  }
}

.text-title {
    font-size: 12px;
    color: gray;
    padding-left: 4px;
  }

.v-hidden {
  visibility: hidden;
}

.v-visibible {
  visibility: visible;
}

.effectRight {
  //z-index: 999999 !important;
  transform: translate(300px, 10px);
  transition: right 2s, transform 1s;
}

.effectLeft {
  transform: translate(-30px, 10px);
  //transition: left 2s, transform 1s;
}

.z-1000-less {
  z-index: -100;
}

.z-1000 {
  z-index: 1000;
}

.text-end {
    text-align: right;
    top: -2rem;
    right: .5rem;
}

.btn-close {
  border-radius: 50%;
  padding: 0px 5px 2px 6px;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.shadow-card {
  box-shadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.2);
}

@media (max-width: 690px) {
  .effectLeft {
    transform: translate(5px, 10px);
    z-index: -100;
    // transition: left 2s, transform 1s;
  }

  /* .effectBottom */
  /* z-index: 100; */
 /*  .effectRight {
    transition: bottom 2s, transform 2s;
    transform: translateY(100%);
  } */

  .effectRight {
    animation-name: movingBox;
    animation-duration: 1300ms;
    animation-direction: alternate;
    transition: bottom 2s, transform 2s;
    transform: translate(5px, 100%) !important;
  }
  /* animation-iteration-count: infinite; */
}
