@keyframes backInRight {
  0% {
    transform: translateX(2000px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    transform: translateX(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.backInRight {
  animation-name: backInRight;
}

.v-hidden {
   visibility: hidden;
}

.visibility-v {
  visibility: visible;
}

.effect {
  position: relative;
  right: -100%;
  transform: translateX(-113%);
  transition: right 2s, transform 2s;
}

.effect-right {
  @extend .backInRight; 
}

ul.react-multi-carousel-track {
  margin-top: 1.5rem;
}
.react-multiple-carousel__arrow {
  background: #84b0c9;
  min-width: 29px;
    min-height: 23px;
  &:hover {
    background: #75a7c494;
  }
}

.react-multiple-carousel__arrow--right {
  right: 0;
}

.react-multiple-carousel__arrow--left {
  left: 0;
} 

@media only screen and (max-width: 500px) and (min-width: 331px) {
  .effect {
    position: relative;
    right: -100%;
    transform: translateX(-150%);
    transition: right 2s, transform 2s;
  }
}

@media (max-width: 330px) {
  .effect {
    right: -87%;
  }
}

// .effect2 {
//   transition: left .3s ease-in-out;
// -webkit-transition: left .3s ease-in-out;
// transition: right .3s ease-in-out;
// -webkit-transition: right .3s ease-in-out;
// }
